@import "variables";

.check-applications-page {
    h1 {
        margin: 0 0 24px 0;
        font-size: 32px;
        font-weight: 700;
    }

    p {
        margin: 0 0 20px 0;
        font-size: 16px;
    }

    .button-group {
        margin: 20px 0;
    }

    .check-results {
        display: flex;
        justify-content: center;
        padding: 20px 20px;
        border: 1px solid $color-black;
        border-radius: 12px;
        background-color: #ffffff;

        > div {
            text-align: center;
        }
        
        &-found {
            h2 {
                margin: 0 0 12px 0;
                font-size: 18px;
                font-weight: 700;
                color: $color-main;
            }
        }

    }
}
