.signup {
    &-form {
        display: flex;
        flex-direction: column;
        gap: 4px 0;
    }

    &-message{
        display: flex;
        flex-direction: column;
        gap: 4px 0;
    }
}