@import "../../variables";

.hero {
    display: grid;
    grid-template-columns: 500px auto;
    color: $color-black;
    min-height: 80vh;
    margin: 0 0 54px 0;

    @media (max-width: $container-xl) {
        & {
            grid-template-columns: 400px auto;
        }
    }

    @media (max-width: $container-lg) {
        & {
            grid-template-columns: 320px auto;
        }
    }

    @media (max-width: $container-md) {
        & {
            display: block;
            margin: 0 0 20px 0;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 72px 0 140px 0;

        @media (max-width: $container-md) {
            & {
                margin: 54px 0 60px 0;
            }
        }

        &-text {
            &-title {
                max-width: 500px;
                margin: 0 0 30px 0;
                font-size: 70px;
                font-weight: 700;
                line-height: 110%;

                @media (max-width: $container-xl) {
                    & {
                        font-size: 56px;
                    }
                }

                @media (max-width: $container-lg) {
                    & {
                        font-size: 48px;
                    }
                }
            }

            &-description {
                max-width: 272px;
                margin: 0 0 24px 0;
                font-size: 24px;
                font-weight: 500;
                line-height: 130%;

                @media (max-width: $container-xl) {
                    & {
                        font-size: 20px;
                    }
                }

                @media (max-width: $container-md) {
                    & {
                        margin: 0 0 32px 0;
                    }
                }
            }
        }
    }

    &-illustration {
        background: url("../../assets/img/illustrations/hero-illustration.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @media (max-width: $container-md) {
            & {
                height: 50vh;
                background-size: cover contain;
            }
        }
    }
}

.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 20px;
    padding: 55px 0 40px 0;
    margin: 0 0 100px 0;
    color: $color-black;

    @media (max-width: $container-lg) {
        & {
            grid-template-columns: 1fr;
            padding: 0 0 0 0;
            margin: 0 0 40px 0;
        }
    }

    &-title {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 40px;
        font-weight: 700;
        line-height: 120%;

        &-highlighted {
            display: inline-block;
            padding: 2px 12px 6px 8px;
            background-color: #fff448;
        }
    }

    &-description {
        > * {
            margin: 0 0 30px 0;
        }

        &-title {
            font-size: 25px;
            font-weight: 700;
            line-height: 130%;
        }

        &-paragraph {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
    }
}

.conditions {
    display: flex;
    flex-wrap: wrap;
    gap: 28px 30px;
    margin: 0 0 100px 0;
    color: $color-black;

    @media (max-width: $container-lg) {
        margin: 0 0 60px 0;
    }

    &-card {
        flex: 1 1 400px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 468px;
        padding: 47px 40px 34px 40px;
        border: 2px solid $color-black;
        border-radius: 30px;

        box-shadow: 10px 10px 0 #fff448;

        &:first-child h3 {
            margin-right: 20%;
        }

        &:last-child h3 {
            margin-right: 35%;
        }

        &-illustration {
            position: absolute;
            top: 27px;
            right: 25px;
            height: 54px;
            width: auto;
        }

        &-content {
            &-title {
                margin-bottom: 52px;
                font-size: 40px;
                font-weight: 700;

                span {
                    padding: 0 4px 0 4px;
                    background-color: #fff448;
                }

                img {
                    position: relative;
                    top: 32px;
                    left: 10px;
                    width: auto;
                    height: 42px;
                }
            }

            > ul {
                font-size: 16px;
                font-weight: 400;

                > li {
                    display: flex;
                    margin: 0 0 20px 0;

                    &::before {
                        flex: 0 0 auto;
                        content: "";
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        margin: 0 20px 0 0;
                        border: 4px solid $color-black;
                        border-radius: 50%;
                    }
                }
            }

            ol {
                margin-left: 34px;
                font-size: 16px;
                font-weight: 400;

                > li {
                    display: flex;
                    margin: 0 0 20px 0;
                }
            }
        }

        &-note {
            display: flex;
            align-items: flex-start;
            gap: 0 8px;

            img {
                flex: 0 0 auto;
                width: 14px;
                height: auto;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;

                span {
                    padding: 0 4px 0 4px;
                    background-color: #fff448;
                }
            }
        }
    }
}

.actions {
    display: grid;
    grid-template-columns: auto 40%;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0;
    align-items: end;
    padding: 40px 40px 40px 40px;
    margin: 0 0 100px 0;
    border: 2px solid $color-black;
    border-radius: 30px;

    @media (max-width: $container-lg) {
        & {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px 0;
            margin: 0 0 50px 0;
        }
    }

    &-title {
        grid-area: 1 / 1 / 2 / 2;
        align-self: flex-start;
        font-size: 40px;
        font-weight: 700;
    }

    &-illustration {
        grid-area: 1 / 2 / 3 / 3;

        @media (max-width: $container-lg) {
            & {
                align-self: center;
            }
        }

        img {
            position: relative;
            left: -20%;
            width: 120%;

            @media (max-width: $container-lg) {
                & {
                    position: static;
                    left: unset;
                    width: 100%;
                }
            }
        }

        &-desktop {
            display: block;

            @media (max-width: $container-lg) {
                & {
                    display: none;
                }
            }
        }

        &-mobile {
            display: none;

            @media (max-width: $container-lg) {
                & {
                    display: block;
                }
            }
        }
    }

    &-buttons {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        gap: 0 30px;

        @media (max-width: $container-lg) {
            & {
                align-self: center;
                justify-content: center;
                gap: 0 20px;
            }
        }

        @media (max-width: $container-md) {
            & {
                flex-direction: column;
                align-self: stretch;
                gap: 20px 0;
            }
        }
    }
}

.quote {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;
    margin: 0 0 100px 0;
    color: $color-black;

    @media (max-width: $container-lg) {
        & {
            display: flex;
            flex-direction: column;
            gap: 32px 0;
            margin: 0 0 50px 0;
        }
    }

    &-person {
        display: flex;
        align-items: flex-end;
        gap: 0 24px;

        @media (max-width: $container-lg) {
            & {
                order: 1;
            }
        }

        img {
            width: 200px;
            border-radius: 50%;
            height: auto;
        }

        div {
            h4 {
                margin: 0 0 8px 0;
                font-size: 20px;
                font-weight: 700;
            }

            p {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    &-content {
        @media (max-width: $container-lg) {
            & {
                order: 0;
            }
        }

        &-title {
            max-width: 500px;
            margin: 0 0 36px 0;
            font-size: 40px;
            font-weight: 700;
        }

        &-text {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        }
    }
}
