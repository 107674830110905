@import "../../variables";

.applicant-form {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
    margin: 0 0 40px 0;

    &-title {
        margin: 0 0 20px 0;
        font-size: 40px;
        font-weight: 700;
    }
}

.form-note {
    margin: 0 0 20px 0;
    padding: 14px 14px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    color: $color-black;
    background-color: $color-main-pale;
}
