@import "variables.scss";

.notificationContainer {
    position: fixed;
    z-index: 999;
    bottom: 10px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 300px;
}

.notification {
    background-color: #ffffff;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(20px);
    animation: fadeInUp 0.3s ease forwards;

    &[data-type="error"] {
        background-color: #fde8e7;
        color: #b42318;

        .notification__button {
            color: #b42318;
        }
    }

    &[data-type="success"] {
        background-color: #e2f6f0;
        color: #0b815a;

        .notification__button {
            color: #0b815a;
        }
    }

    &__button {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
