@import "../../variables";

.user-page {
    min-height: 80vh;
    margin: 40px 0 40px 0;

    &-users-table {
        width: 100%;
        border-radius: 4px;
        border-collapse: separate;
        border-spacing: 0 8px;
        overflow: hidden;
        font-size: 14px;

        tr {
            :is(th, td):first-child {
                border-radius: 8px 0 0 8px;
            }

            :is(th, td):last-child {
                border-radius: 0 8px 8px 0;
            }
        }

        thead tr {
            th {
                background-color: $color-main;
                color: #ffffff;
            }
        }

        tbody tr {
            background-color: #ffffff;
        }

        tr {
            > * {
                padding: 16px 8px 16px 8px;
            }

            > th {
                font-weight: 600;
                text-align: left;
            }

            > td {
                border-bottom: 1px solid #2b91f112;
            }
        }
    }
}
