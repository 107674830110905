@import "../../../variables";

.input {
    position: relative;
    background-color: #ffffff !important;

    &-disabled label,
    &-disabled input {
        background-color: #f1f1f1 !important;
    }

    &-focused label {
        border: 1px solid $color-main;
    }

    &[data-validation="false"] .input-label {
        color: red;
        border: 1px solid red;
    }

    &-label {
        display: block;
        padding: 12px 8px 0;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        background-color: #ffffff;
        color: $color-main;
        transition: all 0.3s;
        user-select: none;
        cursor: pointer;

        &-required {
            color: #ff0000;
        }
    }

    input {
        width: 100%;
        height: 40px;
        padding: 0;
        border: 0;
        background-color: #ffffff;
        font-size: 16px;
    }

    input:focus {
        outline: none;
    }

    &-validation-text {
        font-size: 12px;
        color: #ff0000;
    }
}
