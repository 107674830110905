@import "variables";

.custom-date-input {
    width: 100%;
    padding: 12px 8px 0;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    background: #fff;
    transition: border 0.3s;
    margin-bottom: 4px;

    &-label {
        left: 12px;
        top: 8px;
        font-size: 16px;
        font-weight: 400;
        color: $color-main;
        transition: all 0.2s;
        user-select: none;

        &-required {
            color: #ff0000;
        }
    }

    .react-datepicker__triangle {
        display: none;
    }

    > .react-datepicker-wrapper {
        width: 100%;
        min-height: 40px;
    }

    .react-datepicker {
        border-radius: 8px;
        border: 0;
        font-family: inherit;
        font-size: 14px !important;
    }

    .react-datepicker__header {
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
        background-color: rgba(99, 101, 241, 0.2) !important;
    }

    .react-datepicker__day {
        border-radius: 0;
    }

    .react-datepicker__day--selected {
        background-color: $color-main;
        color: #ffffff;
        font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: rgba(99, 101, 241, 0.2) !important;
    }

    .react-datepicker__navigation-icon::before {
        border-color: $color-main;
        transition: all 0.3s;
    }

    .react-datepicker__navigation:hover *::before {
        border-color: rgba(99, 101, 241, 0.2);
    }

    .react-datepicker__day--outside-month {
        color: #cecece;
    }

    input {
        width: 100%;
        min-height: 36px;
        border: 0;
        outline: 0;
        margin: 0;
        background-color: transparent;
        font-size: 16px;
    }

    input:focus {
        outline: none;
        cursor: pointer;
    }
}
