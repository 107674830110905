@import "../../../variables";

.textarea {
    position: relative;

    &[data-disabled="true"] {
        label {
            background-color: #f1f1f1;
        }

        textarea {
            background-color: #f1f1f1;
        }
    }

    &-big textarea {
        height: 200px;

        @media (max-width: $container-md) {
            & {
                height: 360px;
            }
        }
    }

    &-focused label {
        border: 1px solid $color-main;
    }

    &-label {
        display: block;
        padding: 12px 8px 0;
        margin: 0 0 12px 0;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
        background-color: #ffffff;
        font-size: 16px;
        color: $color-main;
        transition: all 0.3s;
        cursor: pointer;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 0 10px;
            margin: 0 0 8px 0;
        }
    }

    &-input {
        height: 100px;
        width: 100%;
        border: 0;
        background-color: #ffffff;
        resize: none;
        font-size: 16px;

        @media (max-width: $container-md) {
            & {
                height: 240px;
            }
        }

        &:focus {
            border: 0;
            outline: 0;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b5b5;
            border-radius: 8px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-thumb:active {
            background: $color-main;
        }
    }

    &-limit-counter {
        padding: 4px 8px;
        border-radius: 10px;
        background-color: $color-main;
        font-size: 12px;
        color: #ffffff;
    }
}
