@import "variables";

.header-admin-bar {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    background-color: $color-main;

    @media (max-width: $container-md) {
        & {
            display: none;
        }
    }

    &-nav {
        display: flex;
        gap: 0 12px;
        color: #ffffff;

        a {
            color: #ffffff;
        }
    }

    &-control {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0 4px;
        min-width: 120px;
        color: #ffffff;
        cursor: pointer;

        &:hover {
            .header-admin-bar-control-menu {
                max-height: unset;
                padding: 12px 20px;
            }
        }

        &-icon {
            width: 24px;
            height: auto;
        }

        &-info {
            color: #ffffff;
        }

        &-menu {
            position: absolute;
            left: 0;
            top: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 0;
            padding: unset;
            border-radius: 12px;
            background-color: #ffffff;
            overflow: hidden;
            transition: max-height 1s;

            > a {
                padding: 4px 8px;
                font-size: 14px;
                font-weight: 500;
                transition: all 0.3s;

                &:hover {
                    color: $color-main;
                }
            }

            > a:not(:last-child) {
                margin: 0 0 12px 0;
            }
        }
    }
}
