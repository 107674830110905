.application-information {
    padding: 24px 20px;
    border-radius: 12px;
    background-color: #ffffff;

    &-note {
        margin: 0 0 24px 0;
        padding: 8px 12px;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        background-color: #f6f6f6;
        font-size: 12px;

        > span {
            font-weight: 600;
        }
    }

    &-access-denied {
        padding: 20px 12px;
        margin: 0 0 24px 0;
        border: 1px solid red;
        border-radius: 12px;
        background-color: rgb(255, 234, 234);
        font-size: 16px;
        color: red;
    }

    &-section {
        margin: 0 0 24px 0;

        p {
            word-break: break-word;
        }

        p:first-child {
            margin: 0 0 12px 0;
            font-weight: 600;
        }

        p:last-child {
            white-space: pre-line;
        }
    }

    &-link {
        display: block;
        width: fit-content;
        padding: 4px 8px;
        margin: 0 0 8px 0;
        background-color: #efefef;
        border: 1px solid #cecece;
        border-radius: 8px;
        text-decoration: underline;
        color: black;
    }
}
