$xsm: 300px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$color-main: #7d55c7;
$color-main-saturated: #6b37cc;
$color-main-pale: #c5b4e3;

$color-black: #44464a;

$block-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04);
