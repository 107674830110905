.login {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 80vh;
    margin: 52px 0 0 0;

    &-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 420px;
        padding: 36px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 1px 1px 4px 1px #00000010;

        &-title {
            margin: 0 0 32px 0;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            color: #6366f1;
        }

        &-form-group {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            &-label {
                display: block;
                margin: 0 0 8px 0;
                font-size: 16px;
                font-weight: 600;

                > span {
                    display: inline-block;
                    margin: 0 0 0 2px;
                    color: #ff0000;
                }
            }

            &-input {
                width: 100%;
                height: 44px;
                padding: 0 12px;
                margin: 0 0 16px 0;
                border: 1px solid #efefef;
                border-radius: 4px;
                outline: none;
                box-shadow: 1px 1px 4px 1px #00000010;
                font-weight: 600;
                
                &::selection {
                    background: #e3e3e3;
                  }

                &:focus {
                   background-color: #f7f7f7; 
                }
            }

            &-alert {
                width: 100%;
                margin: 0 0 20px 0;
                color: #ff0000;
            }

            &-button {
                align-self: center;
                display: flex;
                align-items: center;
                justify-content: center;
                max-height: 32px;
                padding: 16px 36px;
                margin: 16px 0 16px 0;
                border: unset;
                border-radius: 4px;
                background-color: #6366f1;
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}