@import "../../../variables";

.select-multiple {
    position: relative;
    margin: 0 0 4px 0;

    &-input {
        position: relative;
        width: 100%;
        // height: 40px;
        padding: 12px 8px 0;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
        background: #fff;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
        transition: border 0.3s;

        &[data-disabled="true"] {
            background-color: #f1f1f1;
            cursor: not-allowed;
        }

        &-label {
            left: 12px;
            top: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $color-main;
            transition: all 0.2s;
            user-select: none;

            &-required {
                color: #ff0000;
            }
        }

        &-arrow {
            position: absolute;
            right: 20px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            transition: all 0.3s;

            &[data-reversed="true"] {
                transform: rotateZ(180deg);
            }

            img {
                width: 12px;
                opacity: 0.5;
            }
        }

        &-placeholder {
            display: flex;
            align-items: center;
            min-height: 40px;
        }
    }

    &-options {
        position: absolute;
        top: calc(64px);
        width: 100%;
        max-height: 200px;
        padding: 4px;
        border-radius: 8px;
        background-color: #ffffff;
        overflow-y: scroll;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;
        z-index: 1;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        > div {
            padding: 8px 4px;
            font-size: 16px;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s;
            user-select: none;

            &:hover {
                background-color: rgba(99, 101, 241, 0.2);
            }
        }
    }

    &-selected {
        display: flex;
        gap: 0 8px;
        margin: 12px 0;

        > div {
            display: flex;
            gap: 0 8px;
            padding: 8px 12px;
            background-color: $color-main;
            border-radius: 4px;

            > span {
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
            }

            > button {
                border: 0;
                border-radius: 4px;
                background-color: #ffffff;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    background-color: #e5e7eb;
                }
            }
        }
    }
}
