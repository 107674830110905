@import './../../variables';

.assessment-form {
    &-role-simulator {
        margin: 0 0 24px 0;
    }
}

.stage {
    padding: 24px 20px;
    margin: 0 0 40px 0;
    border-radius: 12px;
    background-color: #ffffff;

    &-title {
        margin: 0 0 20px 0;
        font-size: 20px;
        font-weight: 600;
        color: $color-main;
    }

    &-subtitle {
        margin: 0 0 16px 0;
        font-size: 18px;
        font-weight: 500;
    }
    
    &-form {
        display: flex;
        flex-direction: column;
        gap: 4px 0;
    }

    &-note {
        text-align: center;
    }

    &-rated {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 12px 0;
        border-radius: 8px;
        background-color: #ffffff;

        &[data-assessment="рекомендовано"] {
            .stage-rated-assessment {
                color: green;
            }
        }

        &[data-assessment="не рекомендовано"] {
            .stage-rated-assessment {
                color: red;
            }
        }

        &[data-assessment="потенційно рекомендовано"] {
            .stage-rated-assessment {
                color: orange;
            }
        }

        &-points {
            margin: 0 0 12px 0;
            font-size: 42px;
            font-weight: 600;
        }

        &-point {
            margin: 0 0 12px 0;
            font-size: 12px;
            font-weight: 600;
        }

        &-assessment {
            margin: 0 0 12px 0;
            font-weight: 600;
            font-size: 20px;
        }
    }

    &-not-rated {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100px;
        margin: 0 0 24px 0;
        border: 1px solid #dbdbdb;
        border-radius: 8px;
        background-color: #f5f5f5;

        p {
            font-size: 18px;
            font-weight: 600;
        }
    }
}
