.application-page {
    margin-bottom: 40px;

    &-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 24px 0;

        div {
            display: flex;
            gap: 0 12px;
        }
    }

    &-return {
        display: inline-flex;
        gap: 0 10px;
        padding: 10px 12px;
        background-color: #ffffff;
        border-radius: 8px;
    }

    &-divider {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 0 40px;

        &[data-print="true"] {
            * {
                color: #000000 !important;
                background-color: transparent !important;
                border-color: #cecece !important;
            }

            button,
            a {
                display: none !important;
            }
        }
    }

    &-deleting {
        margin: 20px 0 20px;
        padding: 20px 20px;
        border-radius: 12px;
        background-color: #ffffff;

        p {
            padding: 20px 12px;
            margin: 0 0 24px 0;
            border: 1px solid red;
            border-radius: 12px;
            background-color: rgb(255, 234, 234);
            font-size: 16px;
            color: red;

            span {
                font-weight: 600;
            }
        }
    }

    @media (max-width: 1120px) {
        &-divider {
            grid-template-columns: 1fr;
        }
    }
}
