@import "../../../variables";

.search-input {
    position: relative;

    &-focused label {
        border: 1px solid $color-main;
    }

    &[data-validation="false"] .input-label {
        color: red;
        border: 1px solid red;
    }

    &-label {
        position: relative;
        display: block;
        padding: 12px 8px 0;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        background-color: #ffffff;
        color: $color-main;
        transition: all 0.3s;
        user-select: none;
        cursor: pointer;

        &-required {
            color: #ff0000;
        }
    }

    &-clear {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0;

        button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            padding: 4px;
            border: 0;
            outline: 0;
            cursor: pointer;

            img {
                width: 20px;
                height: auto;
                opacity: 0.5;
            }
        }
    }

    input {
        width: 100%;
        height: 40px;
        padding: 0;
        border: 0;
        background-color: #ffffff;
        font-size: 16px;

    }

    input:focus {
        outline: none;
    }

    &-validation-text {
        font-size: 12px;
        color: #ff0000;
    }
}