@import "../../../../variables";

.client-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 16px 60px;
    border: 1px solid $color-main;
    border-radius: 8px;
    background-color: $color-main;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s;

    @media (max-width: $container-xl) {
        & {
            padding: 12px 50px;
            font-size: 16px;
        }
    }

    @media (max-width: $container-md) {
        & {
            padding: 10px 30px;
            font-size: 14px;
        }
    }

    
    @media (max-width: $container-sm) {
        & {
            padding: 10px 24px;
            font-size: 14px;
        }
    }


    &[data-highlighted="false"] {
        border: 1px solid $color-main;
        background-color: #ffffff;
        color: $color-main;

        &:hover {
            border: 1px solid $color-main;
            background-color: $color-main;
            color: #ffffff;
        }
    }

    &:hover {
        border: 1px solid $color-main-saturated;
        background-color: $color-main-saturated;
        color: #ffffff;
    }

    &:active {
        border: 1px solid $color-main-saturated;
        background-color: $color-main-saturated;
        color: #ffffff;
    }

    &[data-disabled="true"] {
        background-color: #cecece !important;
        border: 1px solid #cecece;
        cursor: not-allowed;
    }

    &[data-disabled="true"]:hover {
        background-color: #cecece;
        border: 1px solid #cecece;
        color: #ffffff;
    }
}
