@import "./_variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.cdnfonts.com/css/pf-dindisplay-pro");

html,
body,
* {
    font-family: "PF DinDisplay Pro", "Montserrat", sans-serif;
}

@media print {
    * {
        color: #000000 !important;
        background-color: transparent !important;
        border-color: #cecece !important;
        box-shadow: 0px 0px 0px transparent !important;
        background-image: none;
    }

    img,
    header,
    footer {
        display: none !important;
    }
}

body {
    background-color: #f6f6f6;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background: #b5b5b5;
    }

    &::-webkit-scrollbar-thumb:active {
        background: $color-main;
    }
}

* {
    box-sizing: border-box;
}

a {
    color: initial;
    text-decoration: unset;
}

.container {
    width: 100%;
    margin: 0 auto;
    z-index: 1;
}

@media (min-width: $xsm) {
    .container {
        max-width: 94%;
    }
}

@media (min-width: $xsm) {
    .container {
        max-width: 90%;
    }
}

@media (min-width: $sm) {
    .container {
        max-width: $container-sm;
    }
}

@media (min-width: $md) {
    .container {
        max-width: $container-md;
    }
}

@media (min-width: $lg) {
    .container {
        max-width: $container-lg;
    }
}

@media (min-width: $xl) {
    .container {
        max-width: $container-xl;
    }
}

@media (min-width: $xxl) {
    .container {
        max-width: $container-xxl;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(-40px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.main-wrapper {
}

.client-wrapper {
    background-color: #ffffff;
}

.page {
    min-height: 100vh;
    margin: 40px 0 0 0;
}
