@import "variables";

.applications-page {
    min-height: 80vh;

    &-control {
        display: flex;
        flex-direction: column;

        > div:first-child {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0 4px;
        }
    }

    &-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 8px;
        font-size: 14px;

        tr {
            :is(th, td):first-child {
                border-radius: 8px 0 0 8px;
            }

            :is(th, td):last-child {
                border-radius: 0 8px 8px 0;
            }

            td {
                vertical-align: middle;
            }
        }

        thead {
            tr {
                th {
                    padding: 16px 12px;
                    background-color: $color-main;
                    font-weight: 600;
                    color: #ffffff;
                    vertical-align: middle;
                    text-align: center;

                    &:nth-child(1) {
                        width: 6%;
                    }

                    &:nth-child(2) {
                        width: 22%;
                    }

                    &:nth-child(3) {
                        width: 14%;
                    }

                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 10%;
                    }

                    &:nth-child(6) {
                        width: 16%;
                    }

                    &:nth-child(7) {
                        width: 10%;
                    }

                    &:nth-child(8) {
                        width: 12%;
                    }
                }
            }
        }

        tbody {
            tr {
                box-shadow: $block-shadow;

                td {
                    padding: 16px 12px;
                    background-color: #ffffff;
                    text-align: center;
                }
            }
        }
    }
}

.table-header-with-tip {
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        opacity: 0.9;
    }

    &-wrapper {
        display: inline-flex;
        flex-direction: column;
        gap: 10px 0;

        > span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        > span:last-child {
            gap: 0 24px;
        }
    }

    &-content {
        padding-right: 8px;
    }

    &-explanation {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        background-color: #ffffff;
        color: $color-main;
        transition: all 0.3s;

        > span {
            position: absolute;
            display: none;
            left: 100%;
            top: 100%;
            padding: 12px 12px;
            border-radius: 4px;
            background-color: #ffffff;
            font-weight: 400;
            color: #000000;
            box-shadow: $block-shadow;
            cursor: pointer;
            z-index: 1;
        }

        &:hover {
            background-color: $color-main;
            color: #ffffff;
            cursor: pointer;

            > span {
                display: unset;
            }
        }
    }
}

.table-header-with-sorting {
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        opacity: 0.9;
    }

    &-content {
        padding-right: 8px;
    }

    &-control {
        display: inline-block;
        width: 10px;
        span {
            border: 0;
            background-color: transparent;

            img {
                display: inline-block;
                margin: 0 8px;
                width: 12px;
                filter: invert(1);
                transition: all 0.3s;
            }

            img[data-order="ASC"] {
                transform: rotateZ(180deg);
            }
        }
    }
}

.table-data-stage {
    &-multiple {
        display: flex;
        justify-content: space-around;
    }

    &-wrapper {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        gap: 8px 0;

        span {
            display: inline-flex;
            justify-content: center;
            max-width: 80px;
        }

        span:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            width: 34px;
            height: 34px;
            border-radius: 8px;
            color: #ffffff;
            font-weight: 600;
        }
    }

    &-accepted {
        background-color: green;
    }

    &-rejected {
        background-color: red;
    }

    &-questionable {
        background-color: orange;
    }

    &-default {
        background-color: rgb(237, 237, 237);
        color: #000000 !important;
    }
}
