@import "../../../variables";

.custom-checkbox {
    &-label {
        display: flex;
        align-items: center;
        min-height: 20px;
        position: relative;
        padding-left: 32px;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        &:hover input ~ .checkmark {
            background-color: $color-main;
            opacity: 0.75;
        }
    }

    &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: $color-main;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border: 1px solid #cecece;
        border-radius: 4px;
        transition: all 0.3s;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 6px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
