@import "variables";

.footer {
    min-height: 20vh;
    background-color: #f4f4f4;
    color: $color-black;

    &-divider {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 40px;
        padding: 40px 0;

        @media (max-width: $container-md) {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px 0;
            }
        }
    }

    &-logo {
        display: flex;
        justify-content: flex-start;

        @media (max-width: $container-md) {
            & {
                order: 0;
            }
        }

        a {
            filter: grayscale(1);
            transition: all 0.3s;

            &:hover {
                filter: grayscale(0);
            }

            img {
                max-width: 240px;
                width: 100%;

                @media (max-width: $container-md) {
                    & {
                        max-width: 140px;
                    }
                }
            }
        }
    }

    &-info {
        @media (max-width: $container-md) {
            & {
                order: 2;
            }
        }

        &-social {
            display: flex;
            justify-content: center;
            gap: 0 24px;
            margin: 0 0 8px 0;

            img {
                width: 28px;
                height: auto;
                transition: all 0.3s;
                filter: grayscale(1);

                &:hover {
                    filter: grayscale(0);
                }
            }
        }

        p {
            margin: 0 0 4px 0;
            text-align: center;
        }
    }

    &-osvitoria {
        display: flex;
        justify-content: flex-end;

        @media (max-width: $container-md) {
            & {
                order: 1;
            }
        }

        a {
            filter: grayscale(1);
            transition: all 0.3s;

            &:hover {
                filter: grayscale(0);
            }

            img {
                max-width: 200px;
                width: 100%;

                @media (max-width: $container-md) {
                    & {
                        max-width: 140px;
                    }
                }
            }
        }
    }
}
