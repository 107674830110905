@import '../../variables';

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 8px;
    margin: 28px 0;
    user-select: none;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 1px 1px 4px 1px #00000010;
        cursor: pointer;
    }

    > div.active {
        background-color: $color-main;
        color: #ffffff;
    }
}
