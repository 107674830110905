@import "../../../variables";

.button {
    position: relative;
    padding: 8px 20px;
    border: 1px solid $color-main;
    border-radius: 8px;
    background-color: $color-main;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s;

    &[data-highlighted="false"] {
        border: 1px solid $color-main;
        background-color: #ffffff;
        color: $color-main;

        &:hover {
            border: 1px solid $color-main;
            background-color: $color-main;
            color: #ffffff;
        }
    }

    &:hover {
        border: 1px solid $color-main-saturated;
        background-color: $color-main-saturated;
        color: #ffffff;
    }

    &:active {
        border: 1px solid $color-main-saturated;
        background-color: $color-main-saturated;
        color: #ffffff;
    }

    &:disabled {
        background-color: #cecece;
        border: 1px solid #cecece;
    }

    &:disabled:hover {
        background-color: #cecece;
        border: 1px solid #cecece;
        color: #ffffff;
    }
}
