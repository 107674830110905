@import "variables";

.header {
    position: sticky;
    z-index: 1;
    top: 0;
    min-height: 10vh;
    border-bottom: 1px solid #e4e4e4;
    background: #ffffff;

    @media (max-width: 720px) {
        background: $color-main;
    }

    &-wrapper {
        height: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &-logo {
        &-image {
            display: block;
            width: 140px;

            &-white {
                display: none;
                width: 120px;
            }

            @media (max-width: $container-md) {
                display: none;
                width: 120px;

                &-white {
                    display: block;
                }
            }
        }
    }

    &-nav {
        &-external {
            display: flex;

            @media (max-width: $container-lg) {
                & {
                    gap: 0 12px;
                    font-size: 14px;
                }
            }

            @media (max-width: $container-md) {
                & {
                    display: none;
                }
            }

            &-dropdown {
                position: relative;
                display: inline-block;
            }

            &-dropdown-menu {
                display: none;
                flex-direction: column;
                position: absolute;
                top: 100%;
                left: 0;
                background-color: white;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
                z-index: 1;
                padding: 10px;
                min-width: 200px;
            }

            &-dropdown-menu a {
                display: block;
                padding: 8px 12px !important;
                color: black;
                text-decoration: none;
            }

            &-dropdown-menu a:hover {
                color: $color-main;
            }

            &-dropdown:hover &-dropdown-menu {
                display: flex;
            }

            a {
                display: inline-flex;
                padding: 12px 12px;
                transition: all 0.3s;
                &:hover {
                    color: $color-main;
                }
            }
        }

        &-internal {
            a {
                padding: 8px 12px;
                border: 1px solid $color-main;
                border-radius: 8px;
                background-color: $color-main;
                font-size: 14px;
                font-weight: 700;
                color: #ffffff;
                transition: all 0.3s;

                @media (max-width: $container-lg) {
                    & {
                        padding: 8px 8px;
                        font-size: 14px;
                    }
                }

                @media (max-width: $container-md) {
                    & {
                        display: none;
                    }
                }

                &:hover {
                    background-color: #ffffff;
                    color: $color-main;
                }
            }
        }
    }

    &-mobile-control {
        display: none;

        @media (max-width: $container-md) {
            & {
                display: block;
            }
        }

        button {
            display: inline-flex;
            background-color: $color-main;
            padding: 6px;
            border: 0;
            border-radius: 4px;

            img {
                width: 24px;
                height: auto;
            }
        }
    }
}

.mobile-nav {
    position: fixed;
    top: 0;
    left: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px 0 0;
    border-bottom: 1px solid #e4e4e4;
    background-color: #ffffff;
    z-index: 1300;
    transition: all 1s;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        background: #b5b5b5;
    }

    &::-webkit-scrollbar-thumb:active {
        background: $color-main;
    }

    &[data-open="true"] {
        left: 0;
    }

    &-control {
        button {
            width: 30px;
            height: 30px;
            margin: 0 0 0 20px;
            border: 0;
            background-color: transparent;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            color: #7e57c6;
            cursor: pointer;
        }
    }

    &-external {
        display: flex;
        flex-direction: column;
        margin: 0 0 24px 0;

        a {
            display: block;
            padding: 10px 10px 10px 20px;
            margin: 0;
            color: #6a6a6a;
            line-height: 20px;
            font-size: 18px;

            &.has-dropdown-menu {
                background-color: #eeeeee;
                font-weight: 600;

                &::after {
                    content: "";
                    border: 2px solid transparent;
                    display: block;
                    width: 7px;
                    height: 7px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    margin-bottom: -5px;
                    position: absolute;
                    bottom: 50%;
                }
            }
        }
    }

    &-internal {
        display: flex;
        justify-content: center;

        a {
            display: block;
            padding: 10px 24px;
            border: 1px solid $color-main;
            border-radius: 8px;
            background-color: $color-main;
            font-size: 14px;
            font-weight: 700;
            color: #ffffff;
            transition: all 0.3s;
        }
    }
}
