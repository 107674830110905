@import "variables.scss";

.applications-dashboard-page {
    &-widgets {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 20px;
        margin: 0 0 40px 0;
    }

    &-widget {
        flex: 1 1 calc(50% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 300px;
        padding: 20px;
        border-radius: 12px;
        background-color: #ffffff;

        &-note {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 320px;
            padding: 10px;
            margin: 0 0 20px 0;
            border-radius: 12px;
            border: 1px solid #f1f1f1;
            background-color: #f8f8f8;
        }

        h3 {
            margin: 0 0 20px 0;
            font-size: 20px;
            font-weight: 700;
            color: $color-main;
        }

        p {
            margin: 0 0 12px 0;
            font-size: 14px;

            span {
                display: inline-flex;
                margin: 0 4px;
                padding: 4px 8px;
                background-color: #f1f1f1;
                border-radius: 4px;
                font-weight: 700;
                color: $color-main;
            }
        }

        ul {
            margin: 0 0 12px 0;
            font-size: 14px;
            li {
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: inline-block;
                    margin-right: 10px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $color-main;
                }

                span {
                    background-color: #f1f1f1;
                    border-radius: 4px;
                    color: #7d55c7;
                    display: inline-flex;
                    font-weight: 700;
                    margin: 0 4px;
                    padding: 4px 8px;
                }
            }
        }
    }

    &-chart-bar {
        padding: 20px;
        margin: 0 0 40px 0;
        border-radius: 12px;
        background-color: #ffffff;
    }
}

.dasboard-chart {
    padding: 20px;
    margin: 0 auto 12px auto;
}
